/** @jsx jsx */
import { graphql, useStaticQuery } from 'gatsby'
import { arrayOf, string, oneOf } from 'prop-types'
import { Container, Heading, jsx, Text } from 'theme-ui'
import { getImage } from 'gatsby-plugin-image'

import ButtonList from '~/components/ButtonList'
import IntroTransition from '~/components/IntroTransition'
import NumeratorDenominator from '~/components/NumeratorDenominator'
import Section from '~/components/Section'
import renderLink from '~/content/renderLink'
import ContentLeftLayout from '~/layout/ContentLeftLayout'
import { ContentfulImagePropType, ContentfulImageType, LinkPropType, LinkType } from '~/types'
import formatPrice from '~/utilities/formatPrice'

const DISPLAY_NAME = 'PricingHeroSection'

const QUERY = graphql`
  query {
    pricing: contentfulPricing {
      ...Pricing
    }
  }
`

const PROP_TYPES = {
  backgroundImage: ContentfulImagePropType,
  elementId: string,
  heading: string.isRequired,
  kicker: string,
  links: arrayOf(LinkPropType),
  textColorScheme: oneOf(['Light', 'Dark']),
}

interface PropTypes {
  backgroundImage?: ContentfulImageType,
  elementId?: string,
  heading: string,
  kicker: string,
  links?: LinkType[],
  textColorScheme: 'Light' | 'Dark',
}

const Component = ({
  backgroundImage,
  elementId,
  heading,
  kicker,
  links,
  textColorScheme,
}: PropTypes) => {
  const { pricing } = useStaticQuery(QUERY)
  const { freePlanStorageGb, paidPlanPricePerTbMonthly } = pricing
  return (
    <Section
      backgroundImage={getImage(backgroundImage?.localFile)}
      belowHeader
      id={elementId}
      textColorScheme={textColorScheme}
    >
      <Container>
        <IntroTransition>
          <ContentLeftLayout>
            <header>
              {kicker && (
                <Text variant="bodyUppercase">
                  {kicker}
                </Text>
              )}
              <Heading
                as="h1"
                variant="headingHero"
              >
                {heading}
              </Heading>
              <Heading
                as="h2"
                sx={{
                  variant: 'text.heading1',
                  marginTop: 2,
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: ['column', null, 'row'],
                  gap: [0.5, null, 1],
                }}
              >
                <Text sx={{ color: 'brand' }}>
                  {freePlanStorageGb}
                  {' '}
                  GB FREE
                </Text>
                <Text variant="prospectBody">
                  or
                </Text>
                <NumeratorDenominator
                  numerator={formatPrice(paidPlanPricePerTbMonthly)}
                  denominator="TB/month"
                />
              </Heading>
              {links && (
                <ButtonList
                  sx={{
                    justifyContent: 'flex-start',
                    marginTop: 4,
                  }}
                >
                  {links.map(({ id, ...link }) => (
                    <li key={id}>
                      {renderLink(link, { scheme: textColorScheme })}
                    </li>
                  ))}
                </ButtonList>
              )}
            </header>
          </ContentLeftLayout>
        </IntroTransition>
      </Container>
    </Section>
  )
}

Component.displayName = DISPLAY_NAME
Component.propTypes = PROP_TYPES

export default Component
